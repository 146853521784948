<template>
        <b-modal id="dealerModal" title="Neuen zwischenstopp hinzufügen" ok-only ok-variant="danger" ok-title="Speichern" size="xl" hide-footer>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" v-model="filter" :value="'Bestellung'">
                        <label class="form-check-label">
                            Bestellungen
                        </label>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" v-model="filter" :value="'Hersteller'">
                        <label class="form-check-label">
                            Lieferanten
                        </label>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" v-model="filter" :value="'Händler'">
                        <label class="form-check-label">
                            Händler
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" v-model="filter" :value="'Großhändler'">
                        <label class="form-check-label">
                            Großhändler
                        </label>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" v-model="filter" :value="'stores'">
                        <label class="form-check-label">
                            Filialen
                        </label>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" v-model="filter" :value="'saved'">
                        <label class="form-check-label">
                            gespeicherte Adressen
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">

                </div>
                <div class="col-md-4">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" v-model="filter" :value="'Benutzerdefiniert'">
                        <label class="form-check-label">
                            Benutzerdefiniert
                        </label>
                    </div>
                </div>
                <div class="col-md-4">

                </div>
            </div>
            <hr>
            <div class="row">
                <!-- Producer -->
                <div class="col-md-12" v-if="filter == 'Hersteller'">
                    <div class="col-md-12 text-right">
                        <div class="input-group input-group-sm" style="margin-top: 0;">
                            <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="loadDistributors" :value="search" @input="$store.commit('routes/changeSearch', $event.target.value)" />
                            <button type="submit" class="btn btn-default btn-sm" @click.prevent="loadDistributors">
                                <i class="fas fa-fw fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <table class="table table-hover table-responsive-sm">
                        <thead>
                            <tr>
                                <th>
                                    <a href="#" @click.prevent="changeSort('distributornumber')">Herstellernummer</a>
                                    <span v-if="this.params.sort_field == 'distributornumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                    <span v-if="this.params.sort_field == 'distributornumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                                </th>
                                <th>
                                    <a href="#" @click.prevent="changeSort('name')">Name</a>
                                    <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                    <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                </th>
                                <th>Aktionen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(producer) in lists.producers.data" :key="producer.id">
                                <td>{{producer.distributornumber}}</td>
                                <td>{{producer.name}}</td>
                                <td>
                                    <b-button v-b-popover.hover.bottom="'Hinzufügen'" size="xs"  @click="createRouteElement({producer_id: producer.id})" variant="primary"><i class="fas fa-fw fa-plus"></i></b-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <template v-if="lists.producers.data.length > 0">
                        <div class="row align-items-center" style="width: 100%">
                            <div class="col-md-9">
                                <pagination class="float-left" :data="lists.producers" @pagination-change-page="loadDistributors" :limit="3"></pagination>
                            </div>
                            <div class="col-md-2">
                                <span class="float-right">Anzeige Eintrag {{ lists.producers.meta.from }} - {{ lists.producers.meta.to }} von {{ lists.producers.meta.total }}</span>
                            </div>
                            <div class="col-md-1">
                                <select class="float-right form-control form-control-sm" v-model="params.per_page">
                                    <option value="25">10 Artikel</option>
                                    <option value="25">25 Artikel</option>
                                    <option value="50">50 Artikel</option>
                                    <option value="75">75 Artikel</option>
                                    <option value="100">100 Artikel</option>
                                    <option value="125">125 Artikel</option>
                                    <option value="150">150 Artikel</option>
                                </select>
                            </div>
                        </div>                        
                    </template>
                </div>
                <!-- Dealers -->
                <div class="col-md-12" v-if="filter == 'Händler'">
                    <div class="col-md-12 text-right">
                        <div class="input-group input-group-sm" style="margin-top: 0;">
                            <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="loadDealers" :value="search" @input="$store.commit('routes/changeSearch', $event.target.value)" />
                            <button type="submit" class="btn btn-default btn-sm" @click.prevent="loadDealers">
                                <i class="fas fa-fw fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <table class="table table-hover table-responsive-sm">
                        <thead>
                            <tr>
                                <th>
                                    <a href="#" @click.prevent="changeSort('number')">Händlerrnummer</a>
                                    <span v-if="this.params.sort_field == 'number' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                    <span v-if="this.params.sort_field == 'number' && this.params.sort_direction == 'desc'" >&darr;</span>
                                </th>
                                <th>
                                    <a href="#" @click.prevent="changeSort('name')">Name</a>
                                    <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                    <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                </th>
                                <th>Aktionen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(dealer) in lists.dealers.data" :key="dealer.id">
                                <td>{{dealer.number}}</td>
                                <td>{{dealer.name}}</td>
                                <td>
                                    <b-button v-b-popover.hover.bottom="'Hinzufügen'" size="xs"  @click="createRouteElement({dealer_id: dealer.id})" variant="primary"><i class="fas fa-fw fa-plus"></i></b-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <template v-if="lists.dealers.data.length > 0">
                        <div class="row align-items-center" style="width: 100%">
                            <div class="col-md-9">
                                <pagination class="float-left" :data="lists.dealers" @pagination-change-page="loadDealers" :limit="3"></pagination>
                            </div>
                            <div class="col-md-2">
                                <span class="float-right">Anzeige Eintrag {{ lists.dealers.meta.from }} - {{ lists.dealers.meta.to }} von {{ lists.dealers.meta.total }}</span>
                            </div>
                            <div class="col-md-1">
                                <select class="float-right form-control form-control-sm" v-model="params.per_page">
                                    <option value="25">10 Artikel</option>
                                    <option value="25">25 Artikel</option>
                                    <option value="50">50 Artikel</option>
                                    <option value="75">75 Artikel</option>
                                    <option value="100">100 Artikel</option>
                                    <option value="125">125 Artikel</option>
                                    <option value="150">150 Artikel</option>
                                </select>
                            </div>
                        </div>                        
                    </template>
                </div>
                <!-- Wholesaler -->
                <div class="col-md-12" v-if="filter == 'Großhändler'">
                    <div class="col-md-12 text-right">
                        <div class="input-group input-group-sm" style="margin-top: 0;">
                            <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="loadWholesalers" :value="search" @input="$store.commit('routes/changeSearch', $event.target.value)" />
                            <button type="submit" class="btn btn-default btn-sm" @click.prevent="loadWholesalers">
                                <i class="fas fa-fw fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <table class="table table-hover table-responsive-sm">
                        <thead>
                            <tr>
                                <th>
                                    <a href="#" @click.prevent="changeSort('number')">Großhändlernummer</a>
                                    <span v-if="this.params.sort_field == 'number' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                    <span v-if="this.params.sort_field == 'number' && this.params.sort_direction == 'desc'" >&darr;</span>
                                </th>
                                <th>
                                    <a href="#" @click.prevent="changeSort('name')">Name</a>
                                    <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                    <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                </th>
                                <th>Aktionen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(wholesaler) in lists.wholesalers.data" :key="wholesaler.id">
                                <td>{{wholesaler.number}}</td>
                                <td>{{wholesaler.name}}</td>
                                <td>
                                    <b-button v-b-popover.hover.bottom="'Hinzufügen'" size="xs"  @click="createRouteElement({wholesaler_id: wholesaler.id})" variant="primary"><i class="fas fa-fw fa-plus"></i></b-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <template v-if="lists.wholesalers.data.length > 0">
                        <div class="row align-items-center" style="width: 100%">
                            <div class="col-md-9">
                                <pagination class="float-left" :data="lists.wholesalers" @pagination-change-page="loadWholesalers" :limit="3"></pagination>
                            </div>
                            <div class="col-md-2">
                                <span class="float-right">Anzeige Eintrag {{ lists.wholesalers.meta.from }} - {{ lists.wholesalers.meta.to }} von {{ lists.wholesalers.meta.total }}</span>
                            </div>
                            <div class="col-md-1">
                                <select class="float-right form-control form-control-sm" v-model="params.per_page">
                                    <option value="25">10 Artikel</option>
                                    <option value="25">25 Artikel</option>
                                    <option value="50">50 Artikel</option>
                                    <option value="75">75 Artikel</option>
                                    <option value="100">100 Artikel</option>
                                    <option value="125">125 Artikel</option>
                                    <option value="150">150 Artikel</option>
                                </select>
                            </div>
                        </div>                        
                    </template>
                </div>
                <!-- Orders -->
                <div class="col-md-12" v-if="filter == 'Bestellung'">
                    <div class="row">
                        <div class="col-md-4">
                            <label>Bestellstatus:</label>
                            <select name="" id="" class="form-control form-control-sm" v-model="params.order_status_id">
                                <option :value="null">-- Filter Bestellstatus --</option>
                                <option v-for="order_state in lists.order_states" :key="order_state.id" :value="order_state.id">{{order_state.description}}</option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <label>Zahlstatus:</label>
                            <select name="" id="" class="form-control form-control-sm" v-model="params.billing_status_id">
                                <option :value="null">-- Filter Zahlstatus --</option>
                                <option v-for="billing_state in lists.billing_states" :key="billing_state.id" :value="billing_state.id">{{billing_state.description}}</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label>Datum:</label>
                            <input type="date" class="form-control form-control-sm" v-model="params.date">
                        </div>
                        <div class="col-md-1">
                            <label>Neu laden</label>
                            <button class="btn btn-primary btn-xs form-control form-control-sm" @click="reload()">
                                <i class="fas fa-sync"></i>
                            </button>
                        </div>
                    </div>
                    <br>
                    <div class="col-md-12 text-right">
                        <div class="input-group input-group-sm" style="margin-top: 0;">
                            <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="loadOrders" :value="search" @input="$store.commit('routes/changeSearch', $event.target.value)" />
                            <button type="submit" class="btn btn-default btn-sm" @click.prevent="loadOrders">
                                <i class="fas fa-fw fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <table class="table table-hover table-responsive-sm">
                        <thead>
                            <tr>
                                <th>
                                    <a href="#" @click.prevent="changeSort('orderdate')">Datum</a>
                                    <span v-if="this.params.sort_field == 'orderdate' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                    <span v-if="this.params.sort_field == 'orderdate' && this.params.sort_direction == 'desc'" >&darr;</span>
                                </th>
                                <th>
                                    <a href="#" @click.prevent="changeSort('ordernumber')">Bestellnr.</a>
                                    <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                    <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                                </th>
                                <th>
                                    <a href="#" @click.prevent="changeSort('customer_name')">Kunde</a>
                                    <span v-if="this.params.sort_field == 'customer_name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                    <span v-if="this.params.sort_field == 'customer_name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                </th>
                                <th>Aktionen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(order) in lists.orders.data" >
                                <tr :key="order.id" v-if="orderAlreadyContainsRoute(order.id)">
                                    <td>{{order.orderdate}}</td>
                                    <td>{{order.ordernumber}}</td>
                                    <td>{{order.customer_name}}</td>
                                    <td>
                                        <b-button v-b-popover.hover.bottom="'Hinzufügen'" size="xs"  @click="createRouteElement({order_id: order.id})" variant="primary"><i class="fas fa-fw fa-plus"></i></b-button>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <template v-if="lists.orders.data.length > 0">
                        <div class="row align-items-center" style="width: 100%">
                            <div class="col-md-9">
                                <pagination class="float-left" :data="lists.orders" @pagination-change-page="loadOrders" :limit="3"></pagination>
                            </div>
                            <div class="col-md-2">
                                <span class="float-right">Anzeige Eintrag {{ lists.orders.meta.from }} - {{ lists.orders.meta.to }} von {{ lists.orders.meta.total }}</span>
                            </div>
                            <div class="col-md-1">
                                <select class="float-right form-control form-control-sm" v-model="params.per_page">
                                    <option value="10">10 Artikel</option>
                                    <option value="25">25 Artikel</option>
                                    <option value="50">50 Artikel</option>
                                    <option value="75">75 Artikel</option>
                                    <option value="100">100 Artikel</option>
                                    <option value="125">125 Artikel</option>
                                    <option value="150">150 Artikel</option>
                                </select>
                            </div>
                        </div>                        
                    </template>
                </div>
                <!-- Custom -->
                <div class="col-md-12" v-if="filter == 'Benutzerdefiniert'">
                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-4">
                            <label>Name*</label>
                            <input class="form-control" type="text" v-model="specific.name">
                        </div>
                        <div class="col-md-4">
                            <label>Telefon</label>
                            <input class="form-control" type="text" v-model="specific.phone">
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-4">
                            <label>Vorname</label>
                            <input class="form-control" type="text" v-model="specific.first_name">
                        </div>
                        <div class="col-md-4">
                            <label>Nachname</label>
                            <input class="form-control" type="text" v-model="specific.last_name">
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-4">
                            <label>Straße*</label>
                            <input class="form-control" type="text" v-model="specific.street">
                        </div>
                        <div class="col-md-2">
                            <label>Hausnr.*</label>
                            <input class="form-control" type="text" v-model="specific.house_number">
                        </div>
                        <div class="col-md-4"></div>
                    </div>
                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-4">
                            <label>Ort*</label>
                            <input class="form-control" type="text" v-model="specific.country">
                        </div>
                        <div class="col-md-2">
                            <label>PLZ*</label>
                            <input class="form-control" type="text" v-model="specific.zipcode">
                        </div>
                        <div class="col-md-4"></div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">
                            <div class="form-group">
                                <input class="form-control-input" type="checkbox" v-model="specific.save">
                                <label class="form-control-label">&nbsp; Adresse speichern?</label>
                            </div>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                </div>
                <!-- saved -->
                <div class="col-md-12" v-if="filter == 'saved'">
                    <div class="col-md-12 text-right">
                        <div class="input-group input-group-sm" style="margin-top: 0;">
                            <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="loadSavedAddresses" :value="search" @input="$store.commit('routes/changeSearch', $event.target.value)" />
                            <button type="submit" class="btn btn-default btn-sm" @click.prevent="loadSavedAddresses">
                                <i class="fas fa-fw fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <table class="table table-hover table-responsive-sm">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Nachname, Vorname</th>
                                <th>Addresse</th>
                                <th>Aktionen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(order) in lists.savedAddresses.data" :key="order.id">
                                <td>{{order.name}}</td>
                                <td>
                                    <template v-if="order.last_name != null || order.first_name != null">
                                        {{order.last_name}}, {{order.first_name}}
                                    </template>
                                </td>
                                <td>{{order.street}} {{order.house_number}} {{order.zipcode}} {{order.city}}</td>
                                <td>
                                    <b-button v-b-popover.hover.bottom="'Hinzufügen'" size="xs"  class="mr-1" @click="createRouteElement({saved_address_id: order.id})" variant="primary"><i class="fas fa-fw fa-plus"></i></b-button>
                                    <b-button v-b-popover.hover.bottom="'Löschen'" size="xs"  class="mr-1" @click="deleteSavedAddress(order.id)" variant="danger"><i class="fas fa-fw fa-trash"></i></b-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Stores -->
                <div class="col-md-12" v-if="filter == 'stores'">
                    <table class="table table-hover table-responsive-sm">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Addresse</th>
                                <th>Aktionen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(order) in lists.stores" :key="order.id">
                                <td>{{order.description}}</td>
                                <td>{{order.street}} {{order.house_number}}</td>
                                <td>
                                    <b-button v-b-popover.hover.bottom="'Hinzufügen'" size="xs" @click="createRouteElement({store_id: order.id})"  variant="primary"><i class="fas fa-fw fa-plus"></i></b-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="text-right">
                <div class="card-tools">
                    <button class="btn btn-secondary" @click="closeModal" style="margin-right: 5px;">
                        Schließen
                    </button>
                    <template v-if="filter == 'Benutzerdefiniert'">
                        <button class="btn btn-primary" @click="handleSubmit" :disabled="filter == null || specific.name == '' || specific.street == '' || specific.house_number == '' || specific.zipcode == '' || specific.country == ''">
                            <i class="fas fa-plus"> Hinzufügen</i>
                        </button>
                    </template>
                </div>
            </div>
        </b-modal>
</template>

<script>
import {mapState} from 'vuex';
export default {
    name: 'RouteDetailAddRouteElementModal',
    props: [
        'route'
    ],
    data() {
        return {
            filter: [],
            lists: {
                savedAddresses: {},
                producers: {},
                dealers: {},
                wholesalers: {},
                orders: {},
                stores: {},
                order_states: {},
                billing_states: {},
            },
            specific: {
                save: false,
                name: '',
                phone: '',
                first_name: '',
                last_name: '',
                street: '',
                house_number: '',
                zipcode: '',
                country: ''
            },
        }
    },
    watch: {
        params: {
            search: {
                handler()
                {
                    this.loadOrders();
                    this.loadDistributors();
                    this.loadDealers();
                    this.loadWholesalers();
                    this.loadStores();
                    this.loadSavedAddresses();
                },
                deep: true
            }
        },
        filter: {
            handler() {
                this.specific.save = false;
                this.specific.name = '';
                this.specific.phone = '';
                this.specific.first_name = '';
                this.specific.last_name = '';
                this.specific.street = '';
                this.specific.house_number = '';
                this.specific.house_number = '';
                this.specific.zipcode = '';
                this.specific.country = '';
                this.params.order_status_id = null;
                this.params.billing_status_id = null;
                this.params.date = null;
                this.$store.commit('routes/changeSearch', null);
                this.loadSavedAddresses();
                this.loadOrders();
            },
            deep: true
        }
    },
    methods: {
        reload()
        {
            this.loadOrders();
            this.loadDistributors();
            this.loadDealers();
            this.loadWholesalers();
            this.loadStores();
            this.loadSavedAddresses();
        },
        closeModal() {
            this.id = null;
            this.filter = null;
            this.$bvModal.hide("dealerModal");
        },
        handleSubmit() {
            this.createRouteElement({
                own_address_id: 0,
                save: this.specific.save,
                items: {
                    name: this.specific.name,
                    first_name: this.specific.first_name,
                    last_name: this.specific.last_name,
                    street: this.specific.street,
                    house_number: this.specific.house_number,
                    zipcode: this.specific.zipcode,
                    city: this.specific.country,
                    phone: this.specific.phone
                }
            });
        },
        deleteSavedAddress(id)
        {
            this.axios
                .delete("/custom-route-address-saved/" + id)
                .then(() => {
                    this.$swal({
                                icon: "success",
                                title: "Adresse gelöscht.",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                            });
                    this.loadSavedAddresses();
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen.'
                    })
                })
        },
        createRouteElement(parameter = {})
        {
            this.route.route_elements.forEach(function(element, index) {
                element.position = index;
            });
            this.axios
                .put('/routes/' + this.$route.params.id, this.route)
                .then(() => {
                    this.axios
                        .post("/route-elements", {
                            route_id: this.$route.params.id,
                            ...parameter
                        })
                        .then(() => {
                            this.$parent.loadRoute();
                            this.$swal({
                                icon: "success",
                                title: "Zwischenstopp wurde eingefügt",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                            });
                        })
                        .catch(() => {
                            this.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Da ist wohl etwas schief gelaufen.'
                            })
                        });
                })
                .catch(() => {
                    this.$router.push({name: 'routes.index'});
                    this.$swal({
                        icon: "error",
                        title: "Diese Strecke wurde bereits geschlossen.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
        },

        orderAlreadyContainsRoute(orderId)
        {
            if(this.route.route_elements.find(element => element.order_id == orderId) == null)
            {
                return true;
            } else
            {
                return false;
            }
        },
        /*

        Load All lists

        */
        loadOrders(page)
        {
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get('/orders', {
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.lists.orders = response.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    });
                });
        },
        loadDistributors(page)
        {
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios 
                .get('/distributors', {
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.lists.producers = response.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    });
                });
        },
        loadDealers(page) {
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios 
                .get('/dealers', {
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.lists.dealers = response.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    });
                });
        },
        loadWholesalers(page) {
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios 
                .get('/wholesalers', {
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.lists.wholesalers = response.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    });
                });
        },
        loadStores() {
            this.axios
                .get("/stores")
                .then((response) => {
                    this.lists.stores = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                })
        },
        loadSavedAddresses(page)
        {
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get("/custom-route-address-saved", {
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.lists.savedAddresses = response.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen.'
                    })
                })
        },
        loadOrderStates()
        {
            this.axios
                .get('/states/order')
                .then((response) => {
                    this.lists.order_states = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                })
        },
        loadBillingStates()
        {
            this.axios
                .get("/states/billing")
                .then((response) => {
                    this.lists.billing_states = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                })
        }
    },
    computed: {
        ...mapState('routes', ['params', 'search', 'page']),
    },
    mounted() {
        this.loadDistributors();
        this.loadDealers();
        this.loadWholesalers();
        this.loadStores();
        this.loadSavedAddresses();
        this.loadOrderStates();
        this.loadBillingStates();

        this.loadOrders();

        this.specific.name = '';
        this.specific.first_name = '';
        this.specific.last_name = '';
        this.specific.street = '';
        this.specific.house_number = '';
        this.specific.zipcode = '';
        this.specific.phone = '';
    }
}
</script>

<style>

</style>